import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BiData, BiGitCompare, BiChip, BiBrain, BiRocket } from 'react-icons/bi';
import About from './component/About';
import Terms from './component/Terms';
import Privacy from './component/Privacy';
import '../style/Home.css';

const Home = () => {
  const navigate = useNavigate();
  const [activeOverlay, setActiveOverlay] = useState(null);

  const openOverlay = (overlayName) => {
    setActiveOverlay(overlayName);
  };

  const closeOverlay = () => {
    setActiveOverlay(null);
  };

  return (
    <div className="main">
      <header className="header">
          <div className="title-container">
              <h1 className="title">Aberrest</h1>
              <h2 className="subtitle">Applied AI Research & Development</h2>
          </div>
          <div className="logo-animated"></div>
      </header>

      <div className="home">
        <div className="banner-header">
          <h1 className="banner">Accelerate Your Workflows</h1>
          <h3 className="subbanner">Aberrest specializes in Advisory and Prototyping to fast-track your business's integration of intelligent automation and agentic systems. We offer complete frontend to backend support to enhance your operations. Get more done faster, more efficiently, and with less.</h3>
          <a href="mailto:projects@aberrest.com" className="contact-link">
            Contact us about your project →
          </a>
        </div>
        <div className="feature-container">
        <div className="selection-container">
          <div className="selection one" onClick={() => openOverlay('about')}>
            <span className="selection-text">About</span>
          </div>

          <div className="selection two" onClick={() => navigate('/blog')}>
            <span className="selection-text">Blog</span>
          </div>
          
          <div className="selection three" onClick={() => navigate('/projects')}>
            <span className="selection-text">Projects</span>
          </div>
  
        </div>
        <div className="workflow-container">
          <div className="workflow-title">
              Transform your operations with AI
            </div>
            <p className="workflow-description">
              We focus on effective, rapid delivery of solutions for your business processes, tailored to your unique company and industry needs, centered on our core principles of security, usability, scalability, and productivity.
              <br />
              <span className="highlight" onClick={() => window.location.href = 'mailto:projects@aberrest.com'}>
                Contact us to learn more.
              </span>
            </p>
            <ul className="workflow-list">
              <li className="workflow-item">
                <div className="workflow-item-title">
                  00 Data, SOP, & Scope
                  <BiData className="workflow-icon" />
                </div>
                <div className="workflow-item-detail">
                  Collect and organize foundational workflow information.
                </div>
              </li>
              <li className="workflow-item">
                <div className="workflow-item-title">
                  01 Process Mapping
                  <BiGitCompare className="workflow-icon" />
                </div>
                <div className="workflow-item-detail">
                  Diagram and translate operational elements into AI primitives.
                </div>
              </li>
              <li className="workflow-item">
                <div className="workflow-item-title">
                  02 Solution Synthesis
                  <BiChip className="workflow-icon" />
                </div>
                <div className="workflow-item-detail">
                  Engineer reasoning systems and agentic orchestration layers.
                </div>
              </li>
              <li className="workflow-item">
                <div className="workflow-item-title">
                  03 Integration & Evaluation
                  <BiBrain className="workflow-icon" />
                </div>
                <div className="workflow-item-detail">
                  Workflow testing and user assessment of solution performance.
                </div>
              </li>
              <li className="workflow-item">
                <div className="workflow-item-title">
                  04 Deployment
                  <BiRocket className="workflow-icon" />
                </div>
                <div className="workflow-item-detail">
                  Launch production system into monitored environment.
                </div>
              </li>
            </ul>
            <div className="availability">
              Contact us about <a href="mailto:projects@aberrest.com" className="highlight">your project.</a>
            </div>
          </div>
        </div>
      </div>

      {activeOverlay && (
        <div className="overlay active" onClick={closeOverlay}>
          <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
            <span className="overlay-close" onClick={closeOverlay}>&times;</span>
            {activeOverlay === 'about' && <About />}
            {activeOverlay === 'terms' && <Terms />}
            {activeOverlay === 'privacy' && <Privacy />}
          </div>
        </div>
      )}

      <footer className="footer">
        <a href="#" onClick={(e) => { e.preventDefault(); openOverlay('terms'); }}>Terms of Use</a>
        <a href="#" onClick={(e) => { e.preventDefault(); openOverlay('privacy'); }}>Privacy Policy</a>
        <span> Aberrest © 2019-Present. All rights reserved.</span>
      </footer>
    </div>
  );
};

export default Home;