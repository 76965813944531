import React from 'react';

const Contact = () => {
    return (
        <div className="contact-policy">
            <h2 className="contact-policy-title">Partner With Us</h2>
            <h3>We partner with companies to accelerate their workflows with AI.</h3>
            <p>Working with <strong>enterprise</strong> and <strong>startup</strong> customers, we build secure, production-ready AI solutions that seamlessly integrate into existing workflows. Our focus is on scalable, private implementations that deliver immediate value.</p>
            <a href="mailto:projects@aberrest.com" className="contact-link">
                Talk to us about your project →
            </a>
        </div>
    );
};

export default Contact;